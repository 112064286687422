<template>
    <div class="c-txt-img" :class="'c-txt-img--' + comp.settings.align">
        <div class="columns">
            <div class="column is-6 is-5-desktop"
            :class="comp.settings.align === 'right' ? 'is-offset-1-desktop' : 'is-offset-1-desktop'">
                <div class="c-txt-img__txt" v-html="comp.settings.txt"></div>
            </div>
            <div class="column is-6 is-5-desktop"
            :class="comp.settings.align === 'right' ? 'is-offset-1-desktop' : ''">
                <progressive-img :id="'img-' + comp.settings.img.cimgt" class="c-txt-img__img" :src="siteURL + comp.settings.img.styles[0].path" :placeholder="comp.settings.img.styles[1].path"/>
            </div>
        </div>
    </div>
</template>

<script>
  import { parallaxItem } from '@/mixins/parallax'

  export default {
    name: 'Comp_txtImg',
    mixins: [parallaxItem],
    props: {
      comp: Object
    },

    data() {
      return {
        siteURL: null,
        parallax: {
          item: `#img-${this.comp.settings.img.cimgt}`,
          triggerElement: `#img-${this.comp.settings.img.cimgt}`,
          animTo: this.comp.settings.img.meta ? this.comp.settings.img.meta.animTo : '-10%',
          animFrom: this.comp.settings.img.meta ? this.comp.settings.img.meta.animFrom : '0'
        }
      }
    },

    created() {
      this.siteURL = process.env.VUE_APP_BACKEND_URL
    }
  }
</script>
